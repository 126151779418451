export const state = () => ({
    folders: []
})
export const getters = {
    folders: (state) => {
        return state.folders
    }
}

export const mutations = {
    SET_FOLDERS(state, data) {
        if(data){
            state.folders = data
        }else{
            state.folders = []
        }
    },
}
