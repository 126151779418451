export const state = () => ({
    banks: [],
    bank: {},
    percent: null,
    banksFiltered: [
        {
            name:'Альфа-Банк',
            place: 3,
            rate: 4.9
        },
        {
            name:'Центр-Инвест',
            place: 16,
            rate: 6.5
        },
        {
            name:'Газпромбанк',
            place: 18,
            rate: 7.5
        },
        {
            name:'Локо-Банк',
            place: 8,
            rate: 4.9
        },
        {
            name:'Банк Открытие',
            place: 13,
            rate: 5.99
        },
        {
            name:'РоссельхозБанк',
            place: 17,
            rate: 7
        },
        {
            name:'Сбербанк',
            place: 1,
            rate: 6.5
        },
        {
            name:'Тинькофф Банк',
            place: 5,
            rate: 4.9
        },
        {
            name:'ВТБ',
            place: 2,
            rate: 4.9
        },
        {
            name:'Уралсиб Банк',
            place: 10,
            rate: 6.8
        },
        {
            name:'Абсолют Банк',
            place: 11,
            rate: 8
        },
        {
            name:'Экспобанк',
            place: 14,
            rate: 4.9
        },
        {
            name:'ОТП БАНК',
            place: 15,
            rate: 4.9
        },
        {
            name:'Совкомбанк',
            place: 9,
            rate: 4.9
        },
        {
            name:'АТБ Банк',
            place: 6,
            rate: 5.5
        },
        {
            name:'Примсоцбанк',
            place: 7,
            rate: 7.3
        },
        {
            name:'Зенит Банк',
            place: 12,
            rate: 8
        },
        {
            name:'Драйв Клик Банк',
            place: 4,
            rate: 4.9
        },
        {
            name:'Банк Оранжевый',
            place: 19,
            rate: 4.9
        },
        {
            name:'Промсвязьбанк',
            place: 20,
            rate: 9.8
        },
    ],
})
export const getters = {
    banksFiltered(state) {
        return state.banksFiltered
    },
    banks: (state) => {
        return state.banks
    },
    percent: (state) => {
        return state.percent
    },
    bank: (state) => {
        return state.bank
    },
    tinkoff: (state) => {
        return state.banks.filter(bank => bank.slug === 'tinkoff-bank')[0]
    },
    alfa: (state) => {
        return state.banks.filter(bank => bank.slug === 'alfa-bank')[0]
    },
    sberbank: (state) => {
        return state.banks.filter(bank => bank.slug === 'sberbank')[0]
    },
    sovkombank: (state) => {
        return state.banks.filter(bank => bank.slug === 'sovkombank')[0]
    },
    raiffeisen: (state) => {
        return state.banks.filter(bank => bank.slug === 'raiffeisen-bank')[0]
    },
    vtb: (state) => {
        return state.banks.filter(bank => bank.slug === 'vtb')[0]
    },
}

export const mutations = {
    SET_BANKS(state, data) {
        state.banks = data
    },
    SET_BANK(state, data) {
        state.bank = data
    },
    SET_PERCENT(state, data) {
        state.percent = data
    },
}
