import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3cc195dd = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _23200f07 = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _27a3c268 = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _153066f0 = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/Contact" */))
const _aabf3878 = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _3e55b19c = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _c6ee43fa = () => interopDefault(import('../pages/Exchange.vue' /* webpackChunkName: "pages/Exchange" */))
const _719d8ba7 = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _6c5c7258 = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _875084b2 = () => interopDefault(import('../pages/Rassrochka.vue' /* webpackChunkName: "pages/Rassrochka" */))
const _64dac16b = () => interopDefault(import('../pages/Reviews.vue' /* webpackChunkName: "pages/Reviews" */))
const _6b99d4ae = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _35f15e7c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8a72bbb0 = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _24ab34e0 = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _55706326 = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _585f193c = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _202a09f8 = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _3cc195dd,
    name: "About"
  }, {
    path: "/Banks",
    component: _23200f07,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _27a3c268,
    name: "Buyout"
  }, {
    path: "/Contact",
    component: _153066f0,
    name: "Contact"
  }, {
    path: "/Cookie",
    component: _aabf3878,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _3e55b19c,
    name: "Credit"
  }, {
    path: "/Exchange",
    component: _c6ee43fa,
    name: "Exchange"
  }, {
    path: "/Favorites",
    component: _719d8ba7,
    name: "Favorites"
  }, {
    path: "/Privacy",
    component: _6c5c7258,
    name: "Privacy"
  }, {
    path: "/Rassrochka",
    component: _875084b2,
    name: "Rassrochka"
  }, {
    path: "/Reviews",
    component: _64dac16b,
    name: "Reviews"
  }, {
    path: "/Thanks",
    component: _6b99d4ae,
    name: "Thanks"
  }, {
    path: "/",
    component: _35f15e7c,
    name: "index"
  }, {
    path: "/Credit/:bank",
    component: _8a72bbb0,
    name: "Credit-bank"
  }, {
    path: "/:category",
    component: _24ab34e0,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _55706326,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _585f193c,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _202a09f8,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
