export const state = () => ({
    allMarks: [],
    arrSet: ['sedan','allroad','hatchback','liftback','universal','created-at','mkpp','akpp','after-1000','after-500', 'before-500','created-at','created-at-c'],
    markFolderGeneration: [],
    allMarksCount: null,
    alphabetMarks: [],
})
export const getters = {
    allMarks: (state) => {
        return state.allMarks
    },
    allMarksCount: (state) => {
        return state.allMarksCount
    },
    alphabetMarks: (state) => {
        return state.alphabetMarks
    },
    markFolderGeneration: (state) => {
        return state.markFolderGeneration
    },
    arrSet: (state) => {
        return state.arrSet
    },
}

export const mutations = {
    SET_ALL_MARKS(state, data) {
        //Подсчет общего кол-ва автомобилей
        data.forEach(val => {
            state.allMarksCount += val.offers_count
            //разбивка по алфавиту
            state.alphabetMarks.push(val.title[0])
            //

        })
        state.alphabetMarks = [...new Set(state.alphabetMarks)].sort()
        state.allMarks = data
    },
    SET_MARK_FOLDER_GENERATION(state, data) {
        state.markFolderGeneration = data
    },
}
