export const state = () => ({
    benefitModal: false,
    benefit: {},
    benefitsCredit: ['sale', 'guaranty', 'first-pay', 'docs'],
    benefitsTradeIn: ['sale-trade', 'guaranty', 'value-market-m', 'docs'],
    benefitsBuyout: ['fast-pay', 'value-market-m', 'value-man', 'fast-time'],
    benefitsCar: ['sale', 'guaranty', 'first-pay', 'docs'],
    benefitsCreditMobile: ['docs', 'insurance'],
    benefits: [
        {
            text: 'Скидка в кредит до 300 000 ₽',
            text_strong: '',
            icon: 'bank',
            description: 'При оформлении сделки по программе автокредитования мы делаем дополнительную скидку на выбранный автомобиль в автосалоне. Скидка суммируется с подарками и другими бонусами.',
            slug: 'sale',
        },
        {
            text: 'Гарантия 2 года',
            text_strong: '',
            icon: 'shield',
            description: 'Мы уверены в своих автомобилях, а потому предоставляем обширную юридическую и техническую гарантию в течение двух лет на любую машину из нашего автоцентра.',
            slug: 'guaranty',
        },
        {
            text: 'Выгода по Trade-In до 300 000 ₽',
            text_strong: '',
            icon: 'bank',
            description: 'При оформлении сделки по программе Trade-In мы делаем дополнительную скидку на выбранный автомобиль в автосалоне. Скидка суммируется с подарками и другими бонусами.',
            slug: 'sale-trade',
        },
        {
            text: 'Автокредит',
            text_strong: 'от 4.9%',
            icon: 'bank',
            description: 'Ставка ниже 4.9% по автокредиту является неправдивой (ниже ставки рефинансирования ЦБ РФ) и как правило предлагается недобросовестными сайтами и автосалонами.',
            slug: 'credit',
        },
        {
            text: 'Первый взнос от 0%',
            text_strong: '',
            icon: 'engine',
            description: 'Хотя процент одобрения с первым взносом значительно повышается, наши клиенты ежедневно получают одобрение по автокредиту и без взноса.',
            slug: 'first-pay',
        },
        {
            text: 'Trade-In как',
            text_strong: 'первый взнос',
            icon: 'trade-in',
            description: 'В качестве первоначального взноса Вы можете сдать свой автомобиль по системе Trade-In с дополнительной скидкой до 150 000 ₽.',
            slug: 'first-pay-trade',
        },
        {
            text: 'По 2 документам',
            text_strong: '',
            icon: 'docs',
            description: 'Благодаря сотрудничеству с лучшими банками, мы упростили процедуру оформления автомобиля — вам понадобятся лишь водительское удостоверение и паспорт.',
            slug: 'docs',
        },
        {
            text: 'Зимняя резина',
            text_strong: 'в подарок',
            icon: 'tires',
            description: 'При покупке автомобиля каждый клиент получает комплект второй комплект резины в подарок.',
            slug: 'tires',
        },
        {
            text: 'ОСАГО',
            text_strong: 'за наш счет',
            icon: 'insurance',
            description: 'При покупке автомобиля в кредит каждый клиент получает ОСАГО в подарок.',
            slug: 'insurance',
        },
        {
            text: 'Рыночная',
            text_strong: 'цена',
            icon: 'ruble',
            description: 'Будьте уверены, что оценка автомобиля будет максимально лояльной, соответствующей рыночным ценам, и Вы получите всю сумму на руки.',
            slug: 'value-market',
        },
        {
            text: 'Рыночная оценка',
            text_strong: '',
            icon: 'ruble',
            description: 'Рыночная оценка проводится профессиональными оценщиками в автосалоне или удобном для вас месте. Совершенно бесплатно для вас.',
            slug: 'value-market-m',
        },
        {
            text: 'Оформление за 30 мин',
            text_strong: '',
            icon: 'engine',
            description: 'За пол часа мы не только оценим ваш автомобиль, но и оформим сделку с выплатой наличных на руки. Таким образом, вы сэкономите уйму времени.',
            slug: 'fast-time',
        },
        {
            text: 'Мгновенная выплата',
            text_strong: '',
            icon: 'credit-card',
            description: 'После оформления документов наши клиенты получают всю сумму наличными на руки. Без задержек в выплате и прочих неудобств.',
            slug: 'fast-pay',
        },
        {
            text: 'Выезд оценщика',
            text_strong: '',
            icon: 'form',
            description: 'Наш автоцентр заключает договор с профессиональным оценщиком, которые моментально выезжает в удобное для вас место и время для оценки автомобиля.',
            slug: 'value-man',
        },
        {
            text: 'Рыночная оценка',
            text_strong: '',
            icon: 'engine',
            description: 'Рыночная оценка проводится профессиональными оценщиками в автосалоне или удобном для вас месте. Совершенно бесплатно для вас.',
            slug: 'value-free',
        },
    ]
})
export const getters = {
    benefits(state) {
        return state.benefits
    },
    benefit: (state) => {
        return state.benefit
    },
    benefitsCredit: (state) => {
        return state.benefits.filter((item) => {
            return state.benefitsCredit.indexOf(item.slug) !== -1;
        })
    },
    benefitsTradeIn: (state) => {
        return state.benefits.filter((item) => {
            return state.benefitsTradeIn.indexOf(item.slug) !== -1;
        })
    },
    benefitsBuyout: (state) => {
        return state.benefits.filter((item) => {
            return state.benefitsBuyout.indexOf(item.slug) !== -1;
        })
    },
    benefitsCar: (state) => {
        return state.benefits.filter((item) => {
            return state.benefitsCar.indexOf(item.slug) !== -1;
        })
    },
    benefitsCreditMobile: (state) => {
      return state.benefits.filter((item) => {
          return state.benefitsCreditMobile.indexOf(item.slug) !== -1;
      })
    },
    benefitModal: (state) => {
        return state.benefitModal
    },
}
export const actions = {
    async openBenefit({commit}, payload) {
        await commit('SET_BENEFIT', payload)
        await commit('SET_BENEFIT_MODAL', true)
    },
    async closeBenefit({commit}) {
        await commit('SET_BENEFIT_MODAL', false)
        await commit('SET_BENEFIT', {})
    }
}
export const mutations = {
    SET_BENEFIT_MODAL(state, data) {
        state.benefitModal = data
    },
    SET_BENEFIT(state, data) {
        state.benefit = data
    },
}
